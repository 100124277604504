import React from "react";
import { BiLoaderAlt } from 'react-icons/bi'; 
import { Slide } from 'react-reveal';

//images
import placeholder_img from '../../images/placeholder.svg';


class LatestNews extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            data: [],
            is_loaded: false, 
        };
    }

    componentDidMount(){
        fetch('https://optosweden.se/admin/api/latest_news.php')
        .then(res => res.json())
        .then( json => {
            this.setState({
                is_loaded: true, 
                data: json,       
            })
        })
    }

    render(){
        const { is_loaded, data} = this.state;
        return(
            <>
                {!is_loaded ? 
                        <div className="w-full flex justify-center items-center py-10">
                            <BiLoaderAlt className="animate-spin w-16 h-16 text-blue-custome" />
                        </div>
                    :
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
                            {
                                data.map(i =>
                                    <Slide bottom> 
                                        <a href={"/nyhet#" + i.id} className="pt-6">
                                            <div className="h-full w-full flex flex-col bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
                                                <div className="w-full -mt-6  px-6 " >
                                                    <div className="w-full overflow-hidden rounded-xl shadow-lg h-44 lg:h-60" >
                                                        <img 
                                                            className="h-full w-full object-cover object-center block " 
                                                            alt="bild"
                                                            src={i.image_size === "0" ? placeholder_img : i.image_url}  />
                                                    </div>
                                                </div>
                                                <div className="w-full h-full flex flex-col justify-between p-6 overflow-hidden" >
                                                    <div>
                                                        <p className=" text-gray-600">{i.created_at.substring(0,10)}</p>
                                                        <a  href={`/nyhet#${i.id}`} className="cursor-pointer">
                                                            <h2 className="mt-4 font-bold text-lg text-black-custome hover:text-blue-custome">{i.title}</h2>
                                                        </a>
                                                        <div className="text-gray-600 mt-4 h-24 overflow-hidden">
                                                            <div dangerouslySetInnerHTML={{ __html: i.body}} />
                                                        </div>
                                                    </div>

                                                    <button  className="w-36 text-sm mt-6 flex items-center justify-center bg-gray-300 px-6 py-1 rounded-full hover:bg-gray-400 cursor-pointer">
                                                        <span className="mr-2">Läs Mer</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-4 w-4" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </a>
                                    </Slide>
                                )
                            }
                        </div>
                    </>
                }
            </>
        );
    }
}
export default LatestNews;