import React from "react";
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { Slide } from 'react-reveal';
import favicon from '../images/favicon.ico';
import Wave from 'react-wavify';

// components
import Testimonial from "../components/testimonial";
import LatestNews from "../components/news/news";

//images
import skanner_img from "../images/skanner.svg";
import skanningsprogramvara_img from "../images/skanningsprogramvara.svg";
import dokumenttolkning_img from "../images/dokumenttolkning.svg";
import service_img from "../images/service.svg";
import attest_img from "../images/partners/attest.svg";
import depona_img from "../images/partners/depona.jpg";
import euro_accident_img from "../images/partners/euro_accident.jpg";
import kontakta_oss_img from "../images/kontakta-oss.svg"

//videos
import video_mp4 from "../images/video/Optosweden hem.mp4";



function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Optosweden AB</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="Vi gör din data till värdefull och användbar information. Vår teknik med AI och maskininlärning tar din dokumenthantering till en ny nivå. Vi har lösningar som effektiviserar er hantering av fakturor, formulär och andra typer av dokument." />
        <meta name="keywords" content="dokumentflöden | dokumentskanning | skanningsprogramvara | dokumentbehandling" />
        <meta property="og:title" content="Optosweden AB" />
        <meta property="og:tyope" content="website" />
        <meta property="og:discription" content="Vi gör din data till värdefull och användbar information. Vår teknik med AI och maskininlärning tar din dokumenthantering till en ny nivå. Vi har lösningar som effektiviserar er hantering av fakturor, formulär och andra typer av dokument." />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:url" content="http://www.optosweden.se/" />
        <link rel="cannonical" href="http://www.optosweden.se/" />
      </Helmet>

      {/***********  Hero Section - END  **********/}
      <section className="bg-gray-100 pb-24">
        <div className="w-screen bg-hero-banner bg-top bg-no-repeat bg-cover">
          <div className="container mx-auto px-4 xl:px-24">
            <div className="pt-28 pb-32">
              <h1 className="text-center text-4xl sm:text-5xl 2xl:text-6xl font-bold text-white filter drop-shadow-sm">Vi digitaliserar dokumentflöden!</h1>
              <p className="md:w-2/3 mx-auto text-center mt-10 lg:text-xl text-white ">
                Vi gör din data till värdefull och användbar information.
                Vår teknik med AI och maskininlärning tar din dokumenthantering till en ny nivå.
                Vi har lösningar som effektiviserar er hantering av fakturor, formulär och andra typer av dokument.
              </p>
            </div>
          </div>
          <div>
            <Wave 
              className="-mb-2"
              fill='#F3F4F6'
              paused={false}
              options={{
              height: 20,
              amplitude: 50,
              speed: 0.15,
              points: 3
              }}
            />
          </div>
        </div>
        <div className="container mx-auto px-4 xl:px-24 -mt-36">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">

            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/dokument">
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={dokumenttolkning_img} alt="Dokumenttolkning"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">Intelligenta automationsplattformar</h2>
                  <p className="mt-4 text-gray-500">
                    Med marknadsledande teknik analyserar vi dina dokument 
                    oavsett format. Vi läser ut den data du behöver och gör 
                    den tillgänglig för dig.
                  </p>
                </div>
              </Link>
            </div>
        
            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/service">
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={service_img} alt="Service och Support"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">Service &amp; Support</h2>
                  <p className="mt-4 text-gray-500">
                    Vi hjälper dig! Vår service-och supportavdelning hjälper er 
                    med företagets skanner och mjukvara. Vi erbjuder service 
                    och support men även utbildning på er skanner och mjukvara 
                    i hela Norden.
                  </p>
                </div>
              </Link>
            </div>

            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/skanningsprogramvara"  >
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={skanningsprogramvara_img} alt="Skanningsprogramvara"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">Skanningsprogramvara</h2>
                  <p className="mt-4 text-gray-500">
                    Hos oss kan ni välja den programvara för bildfångst som passar just 
                    era behov. Samtidigt kan ni vara säkra på att ni får hjälp i 
                    ert val från våra kunniga och erfarna säljare, tekniker och konsulter.
                  </p>
                </div>
              </Link>
            </div>

            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/skanners">
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={skanner_img} alt="skanner"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">Skanners</h2>
                  <p className="mt-4 text-gray-500">
                    På OptoSweden har vi över 20 års erfarenhet av skanningslösningar, 
                    där vi som återförsäljare av flera marknadsledande varumärken har 
                    kompetensen och möjligheterna att guida dig rätt
                  </p>
                </div>
              </Link>
            </div>

          </div>
        </div>
      </section>
      {/***********  Hero Section - END  **********/}



      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>




      {/***********  About Section - START  **********/}
      <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4 xl:px-24">
          <div className="grid xl:grid-cols-2 gap-20">

            <Slide left>
              <div className="w-full">
                <h1 className="title-font font-semibold text-3xl text-gray-900">Kan ett modernt företag ha över 25 års erfarenhet?</h1>
                <p className="leading-relaxed mt-4 text-gray-600">
                  Svaret är enkelt- JA! OptoSweden startade 1994 och har över 25 års erfarenhet.
                  Vi har med våra leveranser såväl som till statlig myndighet som till det lilla 
                  bolaget en enorm erfarenhet av marknaden vi verkar på.
                  <br/><br/>
                  Vår koncern omsätter idag närmare 100 miljoner per år och är trippel AAA 
                  rejtade sedan flera år tillbaka, vilket innebär att du får en finansiellt stark 
                  leverantör som också älskar teknik. Våra kunder får alltså ta del av den modernaste 
                  teknologin som finns på marknaden samtidigt som de kan förlita sig på att vi 
                  har kunskapen och kompetensen att optimera deras dokumenthantering.
                  <br/><br/>
                  Vi vill göra din resa inom digitalisering av dokument enkel, låt oss ta med dig i framtiden.
                  <br/><br/>
                  Se nedan vad några av våra kunder säger om oss och kontakta oss sedan för en demo eller ett samtal om dina behov.
                </p>
              </div>
            </Slide>

            <Slide right>
              <div className="w-full">
                <video src={video_mp4} className="w-full h-full rounded-lg shadow-lg object-cover object-center" autoPlay muted loop></video>
              </div>
            </Slide>

          </div>
        </div>
      </section>
      {/***********  About Section - END  **********/}



      {/***********  Review Section - START  **********/}
      <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4 xl:px-24">
          <Slide bottom>
            <div className="grid lg:grid-cols-3 gap-8">
              <Testimonial company="Attest.nu" logo={attest_img} img_alt="Attest.nu">
                Vi har varit kunder till OptoSweden sedan 2005 och haft flera olika lösningar från dem under åren.
                Sedan några år tillbaka har vi genom OptoSweden haft en Kodak i5650S som är en toppenprodukt!
                Med sina sorteringsmöjligheter är den perfekt för vår verksamhet.
                Vi känner oss alltid nöjda och trygga med deras produkter och service.
              </Testimonial>

              <Testimonial company="Depona AB" logo={depona_img} img_alt="Depona AB">
                Vi har genom OptoSweden dokumenttolkningsplattformen 
                Abbyy Flexicapture. FlexiCapture ger oss möjlighet 
                till en effektiv dokumenthantering, samt en flexibel 
                och stabil lösning som gör att vi kan anpassa våra 
                flöden efter de dokument vi digitaliserar och arkiverar åt våra kunder.
              </Testimonial>

              <Testimonial company="Euro Accident" logo={euro_accident_img} img_alt="Euro Accident">
                Vi har sedan flera år både skanner och skanningsprogramvara från OptoSweden.
                Med service-/supportavtal på dessa produkter kan vi 
                vara trygga med att våra dokumentflöden fungerar 
                som de ska. Dessutom kan vi alltid vara säkra 
                på att våra dokument skannas med bästa kvalité.
              </Testimonial>
            </div>
          </Slide>
        </div>
      </section>
      {/***********  Review Section - END  **********/}
      


      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  News Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Nyheter</h1>
          <p className="mt-4 text-center text-gray-600">Senaste nyheterna från OptoSweden</p>
          <div className="mt-14">
          
          <LatestNews />

          </div>
          <div className="w-full mt-14 flex justify-center items-center">
            <Link to="/nyheter" className="px-6 py-2 rounded-full text-white bg-blue-custome shadow-md hover:opacity-90">Nyfiken på fler nyheter?</Link>
          </div>
        </div>
      </section>
      {/***********  News Section - END  **********/}
      

      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>


      {/***********  Support Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Kontakta oss</h1>
          <p className="mt-4 text-center text-gray-600">Du är alltid varmt välkommen att kontakta oss!</p>
          <div className="mt-14 grid  md:grid-cols-2 gap-8">
            <Slide bottom>
              <Link to="/om-oss">
                <div className="h-full p-6 flex flex-col items-center justify-center bg-gradient-to-b from-white via-white to-gray-100 rounded-md hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
                  <div className="w-full flex items-center justify-center">
                    <img className="w-32" src={kontakta_oss_img} alt="" />
                  </div>
                  <h2 className="mt-4 text-center font-medium text-2xl">Front Office</h2>
                  <p className="mt-4 text-center text-gray-600"><span className="bg-white shadow-md rounded-full px-6 py-1">Växel</span></p>
                  <p className="mt-4 text-center text-blue-custome">08-410 360 98</p>
                </div>
              </Link>
            </Slide>
            <Slide bottom>
              <Link to="om-oss">
                <div className="h-full p-6 flex flex-col items-center justify-center bg-gradient-to-b from-white via-white to-gray-100 rounded-md hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
                  <div className="w-full flex items-center justify-center">
                    <img className="w-32" src={kontakta_oss_img} alt="" />
                  </div>
                  <h2 className="mt-4 text-center font-medium text-2xl">Mattias Horn</h2>
                  <p className="mt-4 text-center text-gray-600"><span className="bg-white shadow-md rounded-full px-6 py-1">Support</span></p>
                  <p className="mt-4 text-center text-blue-custome">08-410 360 95</p>
                </div>
              </Link>
            </Slide>
          </div>
          <div className="w-full mt-14 flex justify-center items-center">
            <Link to="/om-oss" className="px-6 py-2 rounded-full text-white bg-blue-custome shadow-md hover:opacity-90">Träffa fler av oss!</Link>
          </div>
        </div>
      </section>
      {/***********  Support Section - END  **********/}



      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  Map Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Vårt kontor</h1>
          <p className="mt-4 text-center text-gray-600">Fogdevägen 4A, 183 64 Täby</p>
          <div className="mt-14 h-96 bg-white shadow-lg">
            <iframe 
              className="w-full h-full"
              title="google_maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1664.8589882283513!2d18.092931875594783!3d59.44172626589987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9bee7fbb256b%3A0x97ee9d120068107b!2sOptosweden%20AB!5e0!3m2!1sen!2sus!4v1604924344158!5m2!1sen!2sus" 
            />
          </div>
        </div>
      </section>
      {/***********  Map Section - END  **********/}
    </Layout>
  );
}

export default Home;