import React from "react";

function Testimonial(props){
    return(
        <div className="h-full">
            <div className="w-full px-8 py-4 bg-white rounded-lg shadow-xl">
                <div className="flex justify-center -mt-10 text-blue-custome">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-16 w-16" viewBox="0 0 16 16">
                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                    </svg>
                </div>
                <div className="h-full lg:h-80 xl:h-64 2xl:h-48">
                    <p className="mt-2 text-gray-600">{props.children}</p>
                </div>
            </div>

            <div className="px-2 flex justify-start">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-12 h-12 m-0 transform rotate-90 -mt-4 text-white" viewBox="0 0 16 16">
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg>
            </div>

            <div className="flex items-center">
                <img className="object-cover w-16 h-16 rounded-full shadow-lg" alt={props.img_alt} src={props.logo} />
                <h2 className="ml-4 font-semibold text-2xl">{props.company}</h2>
            </div>
        </div>
    )
}
export default Testimonial;